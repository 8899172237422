user {
    .user-profile mat-icon {
        background-color: #002E61;
        color: #FFF;
        border-radius: 15px;
    }
}

.menu-window {
    font-family: "Calibri" !important;
}