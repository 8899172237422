<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation used as fake. CSS is not loading without it-->
<fuse-vertical-navigation style="display: none;"
    class="dark bg-gray-900 print:hidden"
    [appearance]="'empty'">
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full flex-auto flex-col">
    <!-- Header -->
    <!-- <div class="bg-card relative z-49 flex h-16 w-full flex-0 items-center px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"> -->
        <!-- Components -->
        <!-- <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
            <notifications></notifications>
        </div> -->
    <!-- </div> -->
     <div style="display: none;">
        <user></user>
     </div>

    <!-- Content -->
    <div class="flex flex-auto flex-col">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <router-outlet></router-outlet>
        }
    </div>
</div>
