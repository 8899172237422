notifications {
    .notification mat-icon {
        background-color: #002E61;
        color: #FFF;
        border-radius: 15px;
    }
    .notification .count {
        background-color: #DC2626;
        color: #FFF;
    }
}

.notification-window .content {
    font-family: "Calibri";
}