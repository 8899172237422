import { inject, Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { environment } from 'environments/environment';
import { CacNotifyService } from './cac.notify.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { ItmUserService } from '@itm/services/itm-user.service';

@Injectable({
  providedIn: 'root'
})
export class PushNotificationSignalRService {

  private readonly _cacNotifyService = inject(CacNotifyService);
  private readonly _notificationsService = inject(NotificationsService);
  private readonly _itmUserService = inject(ItmUserService);
  
  private hubConnection: signalR.HubConnection;
  private apiUrl: string = '';
  
  constructor() {
    this.apiUrl = environment.apiUrl;
    // this.startConnection();
  }

  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
                            .withUrl(`${this.apiUrl}cac/pushnotification`, { 
                                transport: signalR.HttpTransportType.LongPolling,
                                headers: { 
                                  'Authorization': `Bearer ${this._itmUserService.getAccessTokenLocalStorage()}` 
                                } 
                                // accessTokenFactory: () => this._itmUserService.getAccessTokenLocalStorage()
                            })
                            .withAutomaticReconnect()
                            .build();
                            
    this.hubConnection.on('ReceivePushNotification', (data: any) => {
      if(data){
        const jsonData = JSON.parse(data);
        console.log(jsonData)
        this._cacNotifyService.receiveNotification(jsonData);

        if(jsonData.isSentInApp == true){
          this._notificationsService.addToList(jsonData).subscribe();
        }
      }
    });

    this.hubConnection
      .start()
      .then(() => {
        // console.log('sendMessageListener', this.hubConnection);
        console.log('Push Notification Connection started')
      })
      .catch(err => console.log('Error while starting connection: ' + err))

      this.hubConnection.onclose(err => {
        console.log('SignalR hub connection closed.');
        // this.stopHubAndunSubscribeToServerEvents();
        // this.restartConnection(err);
      });
  }
  
  private stopHubAndunSubscribeToServerEvents(): void {
    this.hubConnection.off('SendBaseLabCodeSamples');
    // this.hubConnection.off('PublishMessageAck');
    this.hubConnection.stop().then(() => console.log('Hub connection stopped'));
  }
  
  public sendPushNotification(branch: string, message: string) {
    this.hubConnection.invoke("SendPushNotification", branch, message);
  }

  // public removeFromFinalApproval(groupName: string) {
  //   this.hubConnection.invoke("RemoveFromFinalApproval", groupName);
  // }
}
